<template>
  <div id="app">
    <theme-change></theme-change>
		<router-view></router-view>
  </div>
</template>

<script>
import ThemeChange from './components/ThemeChange.vue'

export default {
  name: 'App',
  components: {
    ThemeChange  
  }
}
</script>

