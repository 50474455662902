<template>
    <div>
        <div class="headerInfo">
            <!-- header -->
            <div class="container">                
                <div class="d-lg-flex d-block align-items-baseline mt-md-3">
                    <div class="logo">
                        <div class="title">MyCIDR.net</div>
                        <!-- <h1>CIDR.xyz</h1> -->
                    </div>
                    <div class="hdrTxt ms-lg-3">
                        <h1>CIDR Range Visualiser</h1>                        
                    </div>
                </div>
                <div class="cidrTxt d-md-block d-none">
                    <p class="m-0">
                        Classless Inter-Domain Routing, first implemented in 1993, is a protocol used for IP address allocation and network routing. 
                        The CIDR notation represents an IP address and its corresponding network mask in a compact manner. 
                        A CIDR notation consists of an IP address, a forward slash ('/'), and a decimal number. 
                        The decimal number is the number of consecutive 1-bits leading the network mask from left to right. 
                        The number can alternatively be thought of as the network prefix's width (in bits). 
                        Network and cloud engineers that wish to define networks, VPCs, and subnets and 
                        arrange networking accordingly will find the CIDR notation to be quite useful.	
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default{
     
}
</script>
