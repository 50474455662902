import Vue from 'vue'
import Router from 'vue-router'

import CIDR from './view/CIDR.vue'
import Error404 from './view/Error404.vue'

Vue.use(Router)

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [{
            path: '/',
            name: 'cidr',
            component: CIDR
        },
        {
            path: '*',
            name: 'error',
            component: Error404
        },
    ],
})
export default router