<template>
    <div>
        <div>
            <!-- change theme -->
            <div class="hdrtoogle">
                <div class="container">
                    <div class="position-relative">
                        <div class="togleInfo position-absolute">
                            <input type="checkbox" class="checkbox" id="checkbox">
                            <label for="checkbox" class="label">                          
                                <div class='ball'></div>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default{
     
}
</script>
