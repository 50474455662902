<template>
    <div class="error_details">
        <!-- header -->
        <about />

        <div class="error-page">
            <div class="container">
                <h1>404</h1>
                <h3>Not Found</h3>
                <p>The page you are looking for is not found on this website</p>
                <button>
                    <router-link to="/" class="back-btn">Home</router-link>
                </button>
            </div>
        </div>

        <!-- footer -->
        <div class="error-footer">
            <footer-compo />
        </div>
    </div>
</template>

<script>
import About from '../components/About.vue'
import FooterCompo from '../components/Footer.vue'

export default {
    components:{
         About,
         FooterCompo,
     },
}
</script>
