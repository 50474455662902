<template>
    <div class="ftrDetails clearfix">
        <div class="container">
            <div class="ftrInfo d-flex justify-content-between align-items-center">
                <!-- company logo -->
                <div class="copyTxt">
                    <!-- <a href="https://www.zignuts.com/" target="_blank"> -->
                    <a href="https://www.zignuts.com/?utm_source=Google-MyCIDR.net&utm_medium=Footer-Logo&utm_campaign=MyCIDR.net" target="_blank">
                        <p class="d-lg-inline-block d-block me-lg-2 me-0">Proudly made by</p>
                        <img src="../assets/img/footer-logo.png" alt="logo" class="d-inline-block" />
                    </a>
                </div>
                <!-- Social Media -->
                <div class="ftrSocial">
                    <span>Social Media</span>
                    <ul class="list-unstyled m-0">
                        <li>
                            <a href="https://www.linkedin.com/company/zignuts-technolab/" target="_blank">
                                <img src="../assets/img/linkdine.svg" alt="linkdine" class="onhover" />
                                <img src="../assets/img/linkdine-hover.svg" alt="linkdine" class="offhover" />
                            </a>
                        </li>
                        <li>
                            <a href="https://www.facebook.com/ZignutsTechnolab/" target="_blank">
                                <img src="../assets/img/facebook.svg" alt="facebook" class="onhover" />
                                <img src="../assets/img/facebook-hover.svg" alt="facebook" class="offhover" />
                            </a>
                        </li>
                        <li>
                            <a href="https://www.instagram.com/zignutstechnolab/" target="_blank">
                                <img src="../assets/img/instagram.svg" alt="instagram" class="onhover" />
                                <img src="../assets/img/instagram-hover.svg" alt="instagram" class="offhover" />
                            </a>
                        </li>
                        <li>
                            <a href="https://www.youtube.com/channel/UCo7jfXlmkOtjhzhIHnw8rdw/featured" target="_blank">
                                <img src="../assets/img/youtube.svg" alt="youtube" class="onhover" />
                                <img src="../assets/img/youtube-hover.svg" alt="youtube" class="offhover" />
                            </a>
                        </li>
                        <li>
                            <a href="https://twitter.com/ZignutsTechno" target="_blank">
                                <img src="../assets/img/twitter.svg" alt="twitter" class="onhover" />
                                <img src="../assets/img/twitter-hover.svg" alt="twitter" class="offhover" />
                            </a>
                        </li>
                        <li>
                            <a href="https://dribbble.com/ZignutsTechnolab" target="_blank">
                                <img src="../assets/img/dribble.svg" alt="dribbble" class="onhover" />
                                <img src="../assets/img/dribble-hover.svg" alt="dribbble" class="offhover" />
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    
}
</script>